















import Vue from 'vue';
import {mapGetters, mapMutations, mapActions} from 'vuex'
import Tablet from '@/components/Tablet.vue'

export default Vue.extend({
  name: 'PopQuiz',
  components: {
    Tablet
  },
  props: {
    startActive: {
      type: Boolean,
      default: false
    },
    preventHide: {
      type: Boolean,
      default: false
    },
    stage: String
  },
  data () {
    return {
      lines: [],
      questions: [
        {a: true,  q: "One of the first things you should do with an incoming email is to compare from name and from address."},
        {a: false, q: "You should trust all emails."},
        {a: false, q: "The company has invested heavily in security, so it's ok to click on links because IT will handle everything."},
        {a: false, q: "When faced with emotion, we should rush and take action as quick as possible."},
        {a: true,  q: "It's important to take our time when an email comes in that creates emotion."},
        {a: true,  q: "When there are links in an email, we should make sure to hover over them and analyze if they are going somewhere legitimate."},
        {a: false, q: "We should download attachments even if you aren't expecting them."},
        {a: true,  q: "You should never reply to emails from people you don't know."},
        {a: true,  q: "You should never take a USB found in a parking lot or bathroom and plug it into your computer."},
        {a: false, q: "If a website is requesting credentials, you should always give them without checking where you're at."},
        {a: true,  q: "If you do click a link, do not provide credentials - always go to the site directly."},
        {a: false, q: "Two-factor authentication is when a one-time passcode is used to help authenticate to a website or application.  Two-factor authentication could be an SMS or text-based one-time passcode.  Or, it could be an authenticator app (e.g. Google Authenticator, Duo, etc.).  Or, it could be a hardware token.  Regardless, it is a stronger form of authentication.<br><br>Jim knows he uses SMS / text-based authentication to get his one-time passcode to get into Microsoft related applications.  Someone calls Jim and says \"Hi this is Steven with Microsoft.  There's been a lot of fraud out there, so before I continue, I just need to make sure I'm talking to the right person.  Can you give me your one time passcode?\"<br><br>Jim should give Steve his two-factor authentication (2FA).", i: "Treat all one-time passcodes the same way you would treat your password to your computer and never share it with anyone.  Any exceptions should be approved by IT."},
        // {a: false, q: "It's probably okay to give your 2FA code to verify your identity to someone who called you from a company you use."},
        // {a: true,  q: "It's probably okay to give your 2FA code to verify your identity to someone you called at a company you use."},
        // {a: false, q: "Because 2FA codes change so quickly, there's no harm in giving them out."}
      ],
      shuffled: [],
      currentQuestion: -1,
      correct:   0,
      incorrect: 0
    }
  },
  computed: {
    ...mapGetters([
      'stageAnswers',
      'stageHints',
      'hintsRemaining'
    ]),
    correctAnswer () {
      return (this.stageAnswers.hasOwnProperty(this.stage)) ? this.stageAnswers[this.stage] : 'What?'
    },
    hintsAvailable () {
      return (this.stageHints.hasOwnProperty(this.stage)) ? this.stageHints[this.stage] : []
    },
    quizDone () {
      return (this.currentQuestion === this.shuffled.length)
    }
  },
  methods: {
    ...mapActions([
      'loseHint'
    ]),
    show () {
      console.log('PasswordEntry.show')
      this.$refs.tablet.show()
      this.nextQuestion()
    },
    hide () {
      this.$refs.tablet.hide()
    },
    addLines (lines) {
      this.lines = [...this.lines, ...lines]
      this.$nextTick(()=>{
        this.$refs.console.scrollTop = 9999999
      })
    },
    ucFirst (string) {
      return string[0].toUpperCase() + string.substring(1)
    },
    shuffleArray (array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array
    },
    nextQuestion () {
      this.currentQuestion++
      // Show next question, if available.
      if (this.currentQuestion<this.shuffled.length) {
        this.addLines([{text: this.shuffled[this.currentQuestion].q, class: ''}])
        return
      }
      // No more questions
      // this.addLines([
      //   {text: 'End of quiz.', class: ''},
      //   {text: `Your score: ${this.correct} correct, ${this.incorrect} incorrect`, class: 'correct'}
      // ])
      this.addLines([
        {text: 'Congratulations! Click "Done" to complete this stage.', class: ''},
      ])
    },
    answer (answer) {
      if (answer === this.shuffled[this.currentQuestion].a) {
        this.addLines([{text: this.ucFirst(JSON.stringify(answer)), class: 'correct'}])
        this.correct++
      } else {
        this.addLines([{text: "Incorrect. It's actually " + JSON.stringify(this.shuffled[this.currentQuestion].a).toUpperCase(), class: 'incorrect'}])
        this.incorrect++;
        this.shuffled.push(this.shuffled[this.currentQuestion])
      }
      if (this.shuffled[this.currentQuestion].hasOwnProperty('i') && this.shuffled[this.currentQuestion].i) {
        this.addLines([{text: this.shuffled[this.currentQuestion].i, class: 'informational'}])
      }
      console.log(`${this.correct} correct, ${this.incorrect} incorrect`)
      this.nextQuestion()
      // if (this.passwordEntry.trim().toUpperCase() === this.correctAnswer) {
      //   console.log('correct')
      //   this.$emit('correct')
      //   this.hide()
      //   return
      // }
      // console.log('incorrect')
      // this.addLines([
      //   {text: this.passwordEntry, class:'echo'},
      //   {text: 'Incorrect. A 30 second time penalty has been applied.', class:''}
      // ])
    },
    requestAssistance () {

    },
    completeExercise () {
      this.$emit('complete')
    }
  },
  mounted () {
    this.active = this.startActive
    // this.shuffled = this.shuffleArray(this.questions)
    this.shuffled = JSON.parse(JSON.stringify(this.questions))
  }
});
