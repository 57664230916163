import { render, staticRenderFns } from "./PopQuiz.vue?vue&type=template&id=09e7c475&scoped=true&"
import script from "./PopQuiz.vue?vue&type=script&lang=ts&"
export * from "./PopQuiz.vue?vue&type=script&lang=ts&"
import style0 from "./PopQuiz.vue?vue&type=style&index=0&id=09e7c475&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09e7c475",
  null
  
)

export default component.exports